import * as React from 'react'
import { useSpinDelay } from 'spin-delay'
import { Alert } from '../Alert.tsx'
import { Button, type ButtonProps } from './button.tsx'
import { Icon } from './icon.tsx'
import {
	Tooltip,
	TooltipContent,
	TooltipProvider,
	TooltipTrigger,
} from './tooltip.tsx'

export const StatusButton = React.forwardRef<
	HTMLButtonElement,
	ButtonProps & {
		status: 'pending' | 'success' | 'error' | 'idle'
		message?: string | null
		spinDelay?: Parameters<typeof useSpinDelay>[1]
	} & {
		showError?: boolean,
		errorLabel?: string,
		usedWithFields?: boolean, // Only use this if you're not providing an errorLabel
	}
>(
	(
		{ message, status, className, children, spinDelay, showError = true, errorLabel, usedWithFields = true, ...props },
		ref,
	) => {
		const delayedPending = useSpinDelay(status === 'pending', {
			delay: 400,
			minDuration: 300,
			...spinDelay,
		})
		const companion = {
			pending: delayedPending ? (
				<div
					role="status"
					className="inline-flex h-6 w-6 items-center justify-center"
				>
					<Icon name="update" className="animate-spin" title="loading" />
				</div>
			) : null,
			success: (
				<div
					role="status"
					className="inline-flex h-6 w-6 items-center justify-center"
				>
					<Icon name="success" title="success" />
				</div>
			),
			error: null,
			idle: null,
		}[status]

		return (
			<div>
				<Button
					ref={ref}
					className={className}
					{...props}
				>
					<div className="flex items-center gap-4">
						{children}
						{message ? (
							<TooltipProvider>
								<Tooltip>
									<TooltipTrigger>{companion}</TooltipTrigger>
									<TooltipContent>{message}</TooltipContent>
								</Tooltip>
							</TooltipProvider>
						) : (
							companion
						)}
					</div>
				</Button>
				{showError && status === 'error' ? (
					<Alert variant="error" className="mt-2">
						{
							errorLabel ?? (
								usedWithFields ?
									'Please check all fields and try again.' :
									'Please check your request and try again.'
							)
						}
					</Alert>
				) : null}
			</div>
		)
	},
)
StatusButton.displayName = 'Button'
